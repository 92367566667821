var render = function render(){var _vm=this,_c=_vm._self._c;return _c('VRow',{attrs:{"column":"","fill-height":""}},[_c('VCol',{staticStyle:{"flex":"1 1 auto"}},[(_vm.$route.hash === '' && _vm.getQueryFilterValues.length > 0)?_c('CurrentFilter',{staticClass:"rounded-lg pb-6 mx-0 mb-n3 white",attrs:{"filter":_vm.filter,"filter-values":_vm.$route.hash === '' ? _vm.getQueryFilterValues : [],"is-loading":_vm.activity.isLoading},on:{"removeFilter":_vm.onRemoveFilter,"resetFilter":_vm.onResetFilter}}):_vm._e(),_c('DataViewUI',{class:{
        'pa-3 pt-0': _vm.hasExistingData,
      },attrs:{"data":_vm.getData,"headers":_vm.headers,"is-loading":_vm.activity.isLoading,"hide-main-title":_vm.hasExistingData,"hide-records-info":_vm.hasExistingData,"hide-actions":_vm.hasExistingData,"disable-sort":_vm.hasExistingData},on:{"paginate":_vm.onGetMore,"search":_vm.onSearch},scopedSlots:_vm._u([{key:"items-cells",fn:function(props){return [_c('td',[_c('VRow',[_c('VCol',{staticClass:"d-flex align-center"},[_c('span',{staticClass:"d-block text-truncate",staticStyle:{"max-width":"360px"}},[_vm._v(" "+_vm._s(props.item.title)+" ")]),_c('Linkable',{attrs:{"url":_vm.getFullyQualifiedUrlForItem(`${_vm.modelTypeRoute}.view`, props.item.id)}})],1)],1)],1),(_vm.$isUserAdmin && _vm.$viewProgramAsAdmin)?[_c('td',[_c('UserModal',{attrs:{"user":props.item.user}})],1),_c('td',[_c('Tooltip',{attrs:{"text-color":"white","icon":false,"as-button":false,"text":false,"color":props.item.is_active ? 'success' : 'warning',"button-text":props.item.is_active ? 'Yes' : 'No',"title":props.item.is_active
                  ? 'This is active and visible to all allowed roles'
                  : 'This is inactive and invisible to all allowed roles'}})],1),_c('td',[_c('Tooltip',{attrs:{"text-color":"white","icon":false,"as-button":false,"text":false,"color":props.item.allow_multiple_submissions ? 'success' : 'warning',"button-text":props.item.allow_multiple_submissions
                  ? 'Multiple Times'
                  : 'Only Once',"title":props.item.allow_multiple_submissions
                  ? 'Multiple submissions are allowed'
                  : 'Only one submission is allowed per calendar entry'}})],1),_c('td',[_c('VChip',{attrs:{"text-color":props.item.submitted_count ? 'white' : 'default',"color":props.item.submitted_count ? 'black' : 'default'}},[_vm._v(" "+_vm._s(props.item.submitted_count || 0)+" ")])],1),(_vm.isColumnVisible('value', 'due_count'))?_c('td',[_c('VChip',{attrs:{"text-color":props.item.due_count > 0 ? 'white' : 'default',"color":props.item.due_count > 0 ? 'error' : 'default'}},[_vm._v(" "+_vm._s(props.item.due_count || 0)+" ")])],1):_vm._e()]:(_vm.$isUserMentor || _vm.$viewProgramAsMentor)?[_c('td',[(!_vm.hasSaved(props.item.submitted_by_me))?[_c('VChip',{attrs:{"color":"warning"}},[_vm._v(" Not Started ")])]:[(_vm.hasSubmitted(props.item.submitted_by_me))?[_vm._v(" "+_vm._s(_vm.$timezone.formatDateTime( props.item.submitted_by_me.submitted_on ))+" ")]:[_c('VChip',{attrs:{"color":"info"}},[_vm._v(" In Progress ")])]]],2),_c('td',[_c('VChip',{attrs:{"text-color":_vm.hasSubmitted(props.item.submitted_by_me) ? 'white' : 'auto',"color":_vm.hasSubmitted(props.item.submitted_by_me)
                  ? 'success'
                  : 'disabled'}},[_vm._v(" "+_vm._s(_vm.hasSubmitted(props.item.submitted_by_me) ? "Yes" : "No")+" ")])],1),_c('td',[_c('VChip',{attrs:{"text-color":props.item.submitted_count ? 'white' : 'default',"color":props.item.submitted_count ? 'black' : 'default'}},[_vm._v(" "+_vm._s(props.item.submitted_count || 0)+" ")])],1)]:[_c('td',[(!_vm.hasSaved(props.item.submitted_by_me))?[_c('VChip',{attrs:{"color":"warning"}},[_vm._v(" Not Started ")])]:[(_vm.hasSubmitted(props.item.submitted_by_me))?[_vm._v(" "+_vm._s(_vm.$timezone.formatDateTime( props.item.submitted_by_me.submitted_on ))+" ")]:[_c('VChip',{attrs:{"color":"info"}},[_vm._v(" In Progress ")])]]],2),_c('td',[_c('VChip',{attrs:{"text-color":_vm.hasSubmitted(props.item.submitted_by_me) ? 'white' : 'auto',"color":_vm.hasSubmitted(props.item.submitted_by_me)
                  ? 'success'
                  : 'disabled'}},[_vm._v(" "+_vm._s(_vm.hasSubmitted(props.item.submitted_by_me) ? "Yes" : "No")+" ")])],1)],_c('td',{staticClass:"actions"},[(!_vm.$isUserStudent)?_c('Menu',{attrs:{"disabled":_vm.getIsDisabled(props.item),"loading":props.item.isLoading}},[(_vm.hasActionsAccess(props.item))?_c('VListItem',{attrs:{"href":_vm.getHrefForItem(`${_vm.modelTypeRoute}.update`, props.item.id)},on:{"click":function($event){$event.preventDefault();return _vm.$router.push({
                name: 'deliverable.update',
                params: {
                  id: props.item.id,
                  team: _vm.$team.slug,
                },
              })}}},[_c('VListItemTitle',[_c('VIcon',{attrs:{"small":""}},[_vm._v(" edit ")]),_vm._v(" Edit ")],1)],1):_vm._e(),_c('VListItem',{attrs:{"href":_vm.getHrefForItem(`${_vm.modelTypeRoute}.view`, props.item.id)},on:{"click":function($event){$event.preventDefault();return _vm.onSetViewDialogModel(props.item)}}},[_c('VListItemTitle',[_c('VIcon',{attrs:{"small":""}},[_vm._v(" visibility ")]),_vm._v(" View ")],1)],1),_c('VListItem',{on:{"click":function($event){return _vm.openPreviewDialog(props.item.id)}}},[_c('VListItemTitle',[_c('VIcon',{attrs:{"small":""}},[_vm._v(" mdi-file-eye-outline ")]),_vm._v(" Preview ")],1)],1),(_vm.$isUserAdmin)?_c('VListItem',{attrs:{"disabled":!props.item.submitted_count,"href":_vm.getHrefForItem(`${_vm.modelTypeRoute}.report`, props.item.id)},on:{"click":function($event){$event.preventDefault();return _vm.$router.push({
                name: 'deliverable.report',
                params: {
                  id: props.item.id,
                  team: _vm.$team.slug,
                },
              })}}},[_c('VListItemTitle',[_c('VIcon',{attrs:{"small":""}},[_vm._v(" mdi-chart-bar ")]),_vm._v(" Reporting ")],1)],1):_vm._e(),(
                props.item.userNeedsToSubmitReports ||
                  ((_vm.$isUserAdmin ||
                    _vm.$isUserMentor) && !_vm.$viewProgramAsStudent)
              )?_c('VListItem',{attrs:{"href":_vm.getHrefForItem(`${_vm.modelTypeRoute}.submit`, { deliverable: props.item.id })},on:{"click":function($event){$event.preventDefault();return _vm.setSubmittedDialogData(props.item, 'deliverables', true)}}},[_c('VListItemTitle',[_c('VIcon',{attrs:{"small":""}},[_vm._v(" assignment_ind ")]),_vm._v(" Submit For User ")],1)],1):_vm._e(),(!_vm.$isUserAdmin || !_vm.$viewProgramAsAdmin)?_c('VListItem',{attrs:{"href":_vm.getHrefForItem(`${_vm.modelTypeRoute}.submit`, { deliverable: props.item.id })},on:{"click":function($event){$event.preventDefault();return _vm.setSubmittedDialogData(props.item, 'deliverables')}}},[_c('VListItemTitle',[_c('VIcon',{attrs:{"small":""}},[_vm._v(" assignment ")]),(!_vm.hasSaved(props.item.submitted_by_me))?[_vm._v(" Submit Yours ")]:[(
                      _vm.hasSubmitted(props.item.submitted_by_me) &&
                        props.item.allow_multiple_submissions
                    )?[_vm._v(" Submit Again ")]:[_vm._v(" Update Submission ")]]],2)],1):_vm._e(),((_vm.$isUserAdmin || _vm.$isUserMentor) && !_vm.$viewProgramAsStudent)?_c('VListItem',{attrs:{"href":_vm.getHrefForItem(`${_vm.modelTypeRoute}.submitted`, props.item.id)},on:{"click":function($event){$event.preventDefault();return _vm.onShowSubmitted(props.item)}}},[_c('VListItemTitle',[_c('VIcon',{attrs:{"small":""}},[_vm._v(" check ")]),_vm._v(" View Submitted ")],1)],1):_vm._e(),(_vm.hasActionsAccess(props.item) && !_vm.hasExistingData)?_c('VListItem',{attrs:{"to":{
                hash: '#import',
                query: {
                  deliverable_id: props.item.id,
                },
              }}},[_c('VListItemTitle',[_c('VIcon',{attrs:{"small":""}},[_vm._v(" south ")]),_vm._v(" Import ")],1)],1):_vm._e(),((_vm.$isUserAdmin || _vm.$isUserMentor) && !_vm.$viewProgramAsStudent && !_vm.hasExistingData)?_c('VListItem',{attrs:{"to":{
                hash: '#export',
                query: {
                  deliverable_id: props.item.id,
                },
              }}},[_c('VListItemTitle',[_c('VIcon',{attrs:{"small":""}},[_vm._v(" north ")]),_vm._v(" Export ")],1)],1):_vm._e(),(_vm.$isUserAdmin && _vm.$viewProgramAsAdmin)?_c('VListItem',{on:{"click":function($event){$event.preventDefault();return _vm.onDuplicateItem(props.item, false, false, true, true)}}},[_c('VListItemTitle',[_c('VIcon',{attrs:{"small":""}},[_vm._v(" content_copy ")]),_vm._v(" Duplicate ")],1)],1):_vm._e(),(_vm.hasActionsAccess(props.item))?_c('VListItem',{on:{"click":function($event){$event.preventDefault();return _vm.onDeleteItem(props.item)}}},[_c('VListItemTitle',{attrs:{"color":"error"}},[_c('VIcon',{attrs:{"small":"","color":"error"}},[_vm._v(" delete ")]),_vm._v(" Delete ")],1)],1):_vm._e()],1):_c('VBtn',{attrs:{"color":_vm.hasSaved(props.item.submitted_by_me) ? 'info' : '',"href":_vm.getHrefForItem(`${_vm.modelTypeRoute}.submit`, { deliverable: props.item.id })},on:{"click":function($event){$event.preventDefault();return _vm.setSubmittedDialogData(props.item, 'deliverables')}}},[_c('VIcon',{attrs:{"small":""}},[_vm._v(" assignment ")]),(!_vm.hasSaved(props.item.submitted_by_me))?[_vm._v(" Submit Yours ")]:[(
                  _vm.hasSubmitted(props.item.submitted_by_me) &&
                    props.item.allow_multiple_submissions
                )?[_vm._v(" Submit Again ")]:[_vm._v(" Update Submission ")]]],2)],1)]}}])}),_c('FormDialog',{attrs:{"hide-actions":"","custom-class":"no-shadow dialog-card-shadow","scrollable":false,"is-card-center":_vm.$isUserAdmin,"transparent":_vm.$isUserAdmin,"hide-title":_vm.$isUserAdmin,"max-width":_vm.$isUserAdmin ? '100%' : '570px',"current-model":_vm.currentModel,"is-dialog-open":_vm.dialogs.form,"title":_vm.currentModel
          ? _vm.currentModel.title
          : `Add ${_vm.featureName(_vm.modelType.capitalize()).singularize()}`,"model-type":_vm.modelType,"button-text":_vm.$isUserAdmin ? 'Save Form' : 'Submit'},on:{"toggle:form:dialog":_vm.onToggleFormDialog},scopedSlots:_vm._u([{key:"default",fn:function(props){return [(_vm.hasActionsAccess())?_c('Save',{ref:"form",attrs:{"mode":"dialog","form-props":props,"id":_vm.currentModel ? _vm.currentModel.id : null},on:{"saved":(result) => _vm.$emit('updated', result),"duplicated":(result) => _vm.$emit('duplicated', result),"updateModel":function($event){return _vm.onToggleFormDialog('form')},"toggle:form:dialog":_vm.onToggleFormDialog,"deleteItem":(params) => _vm.onDeleteItem(params, null, null, true)}}):_c('Submit',{ref:"form",attrs:{"mode":"dialog","deliverable":_vm.currentModel}})]}}])}),_c('Confirm',{ref:"confirmDelete"}),_c('Confirm',{ref:"confirmDuplicate",attrs:{"confirm-color":"info","cancel-color":"disabled"}}),_c('Dialog',{attrs:{"max-width":"75%","is-dialog-open":_vm.dialogs.view,"title":`View ${_vm.featureName('Deliverable')}`},on:{"toggle:dialog":_vm.onToggleViewDialog}},[_c('ViewComponent',{ref:"viewItem",attrs:{"mode":"dialog","id":_vm.currentModel ? _vm.currentModel.id : null}})],1),_c('Dialog',{attrs:{"fullscreen":"","is-dialog-open":_vm.submittedDialogs.submitted,"title":`Submitted ${_vm.featureName('Deliverables')}: ${
          _vm.submittedModel.title ? _vm.submittedModel.title : ''
        }`},on:{"toggle:dialog":_vm.onToggleSubmittedDialog}},[_c('Submitted',{attrs:{"mode":"dialog","deliverable-id":_vm.submittedModel.id,"is-from-existing-data":_vm.hasExistingData}})],1),_c('Dialog',{attrs:{"max-width":"640px","card-text-class":"overflow-hidden","dismissable":false,"persistent":true,"is-dialog-open":_vm.submittedDialogs.deliverables},on:{"toggle:dialog":_vm.onToggleSubmittedDeliverablesDialog},scopedSlots:_vm._u([{key:"default",fn:function(parent){return [(_vm.submitted)?_c('SubmitForm',{attrs:{"mode":"dialog","use-id-from-route":!_vm.hasExistingData,"submit-for-user-mode":_vm.submitForUserMode,"id":_vm.submitForUserMode ? null : (_vm.submittedModel.submitted_by_me || {}).id,"existing":_vm.submitForUserMode ? null : _vm.submittedModel.submitted_by_me,"deliverable":_vm.submittedModel,"items":_vm.submitted.data,"parent-uid":parent.uid,"copyable-link":_vm.getHrefForItem(`${_vm.modelTypeRoute}.submit`, { deliverable: _vm.submittedModel.id })},on:{"toggle:dialog":_vm.onToggleSubmittedDeliverablesDialog}}):_vm._e()]}}])}),_c('Dialog',{attrs:{"max-width":"520px","dismissable":false,"persistent":true,"is-dialog-open":_vm.dialogs.preview.status}},[_c('VSkeletonLoader',{attrs:{"grid-list-lg":"","fluid":"","type":"card-heading, card","loading":!_vm.dialogs.preview.item}},[_c('SubmitForm',{attrs:{"preview-mode":"","mode":"dialog","use-id-from-route":false,"deliverable":_vm.dialogs.preview.item,"existing":_vm.dialogs.preview.item,"copyable-link":_vm.getFullyQualifiedUrlForItem(`${_vm.modelTypeRoute}.submit`, { deliverable: _vm.dialogs.preview.item?.id })},on:{"toggle:dialog":_vm.closePreviewDialog}})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }