var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"position":"relative","height":"100%"}},[(_vm.isBackEnabled)?_c('VBtn',{staticClass:"ml-7 my-3",on:{"click":function($event){return _vm.$router.go(-1)}}},[_c('VIcon',{staticClass:"pr-1"},[_vm._v(" mdi-arrow-left ")]),_vm._v(" Back ")],1):_vm._e(),_c('VNavigationDrawer',{staticClass:"adjust-items-padding",style:({
      'min-width': _vm.drawerMinWidth,
    }),attrs:{"permanent":"","floating":"","disable-resize-watcher":"","height":"auto","mini-variant-width":"80px","id":"side-bar-menu","color":"#f4f4f9","mini-variant":_vm.mini}},[_c('VList',{staticClass:"pl-7",staticStyle:{"overflow":"hidden auto"},attrs:{"nav":"","dense":"","height":_vm.mini ? 'auto' : _vm.height}},[_c('VListItem',{staticClass:"header px-0",style:({
          opacity: _vm.mini ? 0 : 1,
        })},[_c('VListItemTitle',{staticStyle:{"font-weight":"600 !important","font-size":"16px !important"}},[_vm._v(" "+_vm._s(_vm.menuItems.header)+" ")])],1),_vm._l((_vm.menuItems.items),function(item,index){return [(item.subGroup)?_c('VListGroup',{key:index,attrs:{"append-icon":"","active-class":_vm.mini ? 'active-item' : '',"value":_vm.getItemGroup(item)},on:{"click":function($event){return _vm.$router.push(item.subGroupItems[0].route)}},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('VTooltip',{attrs:{"right":"","disabled":!_vm.mini},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('div',_vm._g({class:{
                    'px-4': _vm.mini,
                  }},on),[(item.isImage || item.icon)?_c('VListItemIcon',{class:{
                      'my-3': true,
                      'mr-3': !_vm.mini,
                      'mx-0': _vm.mini,
                    }},[(item.isImage)?_c('img',{attrs:{"src":item.icon}}):_c('VIcon',{attrs:{"color":"rgba(0, 0, 0, 0.87)"}},[_vm._v(" "+_vm._s(item.icon)+" ")])],1):_vm._e()],1)]}}],null,true)},[_vm._v(" "+_vm._s(item.title)+" ")]),_c('VListItemTitle',{staticClass:"mt-n3"},[_c('BetaBadge',{class:{
                  'd-block text-truncate': !item.beta,
                },attrs:{"enable-tooltip":"","is-beta":item.beta}},[_vm._v(" "+_vm._s(item.title)+" ")])],1)]},proxy:true}],null,true)},[(!_vm.mini)?_vm._l((item.subGroupItems),function(subItem,key){return _c('VListItem',{key:`sub-group-item-${key}`,attrs:{"exact":"","exact-active-class":"active-item","value":true,"to":subItem.route}},[(subItem.icon)?_c('VListItemIcon',{staticClass:"my-3 ml-0 mr-3"},[(subItem.isImage)?_c('img',{attrs:{"src":subItem.icon}}):_c('VIcon',{attrs:{"color":"rgba(0, 0, 0, 0.87)"}},[_vm._v(" "+_vm._s(subItem.icon)+" ")])],1):_vm._e(),_c('BetaBadge',{class:{
                  'd-block text-truncate': !subItem.beta,
                },attrs:{"enable-tooltip":"","is-beta":subItem.beta}},[_c('VListItemTitle',[_vm._v(_vm._s(subItem.title))])],1)],1)}):_vm._e()],2):_c('VListItem',{key:`menu-item-${index}`,attrs:{"exact":"","exact-active-class":"active-item","value":true,"disabled":item.disabled || false,"to":item.route || { name: item.routeName, params: { team: _vm.$team.slug } }}},[_c('VTooltip',{attrs:{"right":"","disabled":!_vm.mini},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('div',_vm._g({class:{
                  'px-4': _vm.mini,
                }},on),[(item.routeGroup || item.icon)?_c('VListItemIcon',{class:{
                    'my-3': true,
                    'mr-3': !_vm.mini,
                    'mx-0': _vm.mini,
                  }},[(item.isImage)?_c('img',{attrs:{"src":item.icon}}):_c('VIcon',{attrs:{"color":"rgba(0, 0, 0, 0.87)"}},[_vm._v(" "+_vm._s(item.icon)+" ")])],1):(_vm.mini)?_c('b',[_vm._v(_vm._s(item.title.charAt(0).capitalize()))]):_vm._e()],1)]}}],null,true)},[_vm._v(" "+_vm._s(item.title)+" ")]),_c('VListItemTitle',{class:{
              'mt-n3': true,
              'pr-8': item.beta,
            }},[_c('BetaBadge',{class:{
                'd-block text-truncate': !item.beta,
              },attrs:{"offset-y":"30%","enable-tooltip":item.enableBetaTooltip,"is-beta":item.beta}},[_vm._v(" "+_vm._s(item.title)+" ")])],1)],1)]})],2)],1),_c('VTooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('VBtn',_vm._g({ref:"collapseButton",style:({
          bottom: _vm.bottom,
        }),attrs:{"fab":"","rounded":"","height":"32px","width":"32px","min-width":"32px","id":"menu-collapse-button"},on:{"click":function($event){_vm.forceExpand = !_vm.forceExpand}}},on),[_c('VIcon',[_vm._v(" "+_vm._s(_vm.forceExpand ? "mdi-chevron-left" : "mdi-chevron-right")+" ")])],1)]}}])},[_vm._v(" "+_vm._s(_vm.forceExpand ? "Collapse" : "Expand")+" ")])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }