<template>
  <VSkeletonLoader
    grid-list-lg
    fluid
    type="card"
    :loading="(isFirstLoad && !isDashboard) || isLoading"
  >
    <VRow
      row
      wrap
    >
      <VCol
        v-if="deliverables.data.length === 0"
        cols="12"
      >
        <NoRecordCard
          :searched-text="filter.s"
          :action-text="`Create a ${ featureName(tabName, 'singularize') }`"
          :title="`You don’t have any ${ featureName(tabName) } yet`"
          :description="`You can create ${ featureName(tabName).toLowerCase() } relevant to your ${ featureName('Program', 'singularize') } and make them available to ${ featureName('Students') } and/or ${ featureName('Mentors') }. Surveys, weekly check-ins, file submissions — ${ featureName(tabName).toLowerCase() } are a great way to engage with and track the progress of your team and participants.`"
          @clicked="$emit('addSection', sections[activeTab])"
        />
      </VCol>
      <template v-else>
        <VCol
          v-if="!isTilesView"
          cols="12"
        >
          <DeliverablesData
            :existing-data="deliverables.data"
            @duplicated="(result) => localDeliverables.data.unshift(result)"
            @deleted="
              (id) =>
                (localDeliverables.data = localDeliverables.data.filter(
                  (deliverable) => deliverable.id != id
                ))
            "
            @updated="
              (result) =>
                (localDeliverables.data = localDeliverables.data.map((deliverable) =>
                  deliverable.id === result.id ? result : deliverable
                ))
            "
          />
        </VCol>
        <DataViewUI
          v-else
          hide-actions
          hide-main-title
          hide-records-info
          default-view-mode="grid"
          background-color="transparent"
          :data="deliverables"
          :md="colMd"
          :lg="colLg"
          :enable-infinite-loader="enableInfiniteLoader"
          @infiniteLoad="onInfiniteLoad"
        >
          <template
            slot="card"
            slot-scope="props"
          >
            <IndexCard
              hide-actions
              height="200px"
              content-min-height="150px"
              :loading="props.item.loading || props.item.isDuplicating"
              :visibility-icon-color="
                props.item.is_overdue && false ? '#ffffff' : 'inherit'
              "
              :background-color="
                props.item.is_overdue && false ? '#ff2a7a' : '#ffffff'
              "
              :card-shadow="
                props.item.is_overdue && false
                  ? '0 3px 8px 0 rgba(255, 42, 122, 0.5)'
                  : '0 3px 8px 0 rgba(216, 220, 240, 0.5)'
              "
              :item="props.item"
              :item-type="tabName"
              :parent-model="model"
              :hide-menu="hideCardMenu(props.item)"
              :count="(model.deliverables_count - deliverables.data.length) + 1"
              :show-count="checkShowCount(props.index)"
              :alt-processing="props.item.processingDuplicate"
              @onClick="setSubmittedDialogData(props.item, 'deliverables', $isUserAdmin)"
              @duplicationDone="onDuplicationDone"
              @showMore="$emit('toggleTab', tabName.toLowerCase().pluralize())"
              @enableItemLoading="(status) => $set(localDeliverables.data[props.index], 'loading', status)"
            >
              <template slot="title-text">
                <span
                  class="text-truncate section-card-title"
                  :style="{
                    color: props.item.is_overdue && false ? '#ffffff' : '#000000',
                  }"
                >
                  {{ props.item.title }}
                </span>
              </template>
              <template slot="card-content">
                <VRow
                  v-if="props.item.metadata && props.item.metadata.length > 0"
                  class="ma-0 metadata-section"
                >
                  <VCol cols="12">
                    <VList class="metadata">
                      <VListItem
                        v-for="(question, index) in props.item.metadata.slice(0, 3)"
                        class="d-block pa-0 text-truncate"
                        :key="question.id"
                      >
                        <VListItemContent class="d-block text-truncate py-0">
                          {{ index + 1 }}. {{ question.name }}
                        </VListItemContent>
                      </VListItem>
                    </VList>
                  </VCol>
                </VRow>
                <!-- <VChip
                  text-color="white"
                  class="pl-4 status-chip"
                  :color="props.isCardHidden ? 'rgba(47, 53, 63, 0.75)' : props.item.is_overdue ? '#ff2a7a' : '#2f353f'"
                >
                  <VIcon 
                    left
                    size="16px"
                  >
                    {{ props.item.is_overdue ? "mdi-alert-circle" : "mdi-calendar-month-outline" }}
                  </VIcon>
                  {{ props.item.is_overdue ? "Overdue" : getDue(props.item) }}
                </VChip> -->
              </template>
            </IndexCard>
          </template>
        </DataViewUI>
        <Dialog
          v-slot="parent"
          max-width="640px"
          card-text-class="overflow-hidden"
          :dismissable="false"
          :persistent="true"
          :is-dialog-open="submittedDialogs.deliverables"
          @toggle:dialog="onToggleSubmittedDeliverablesDialog"
        >
          <SubmitForm
            v-if="submitted"
            mode="dialog"
            :use-id-from-route="false"
            :program="model"
            :submit-for-user-mode="submitForUserMode"
            :id="(submittedModel.submitted_by_me || {}).id"
            :existing="submittedModel.submitted_by_me"
            :deliverable="submittedModel"
            :items="submitted.data"
            :parent-uid="parent.uid"
            @toggle:dialog="onToggleSubmittedDeliverablesDialog"
          />
        </Dialog>
        <Dialog
          fullscreen
          :is-dialog-open="submittedDialogs.submitted"
          :title="
            `Submitted ${featureName('Deliverables')}: ${
              submittedModel.title ? submittedModel.title : ''
            }`
          "
          @toggle:dialog="onToggleSubmittedDialog"
        >
          <Submitted
            mode="dialog"
            :use-id-from-route="false"
            :deliverable-id="submittedModel.id"
          />
        </Dialog>
      </template>
    </VRow>
  </VSkeletonLoader>
</template>
<script>
import { mapActions } from "vuex";
import ViewProgramSection from "@/mixins/ViewProgramSection";
import HasDueMixin from "@/mixins/HasDueOn";
import HasSubmittedDataMixin from "@/mixins/HasSubmittedData";
import DeliverablesData from "@/components/Deliverables/Data";
import SubmitForm from "@/components/Deliverables/SubmitForm";
import Submitted from "@/components/Deliverables/Submitted";
import IndexCard from "../Card";
import NoRecordCard from "./NoRecordCard";
export default {
  name: "Deliverables",
  mixins: [ViewProgramSection, HasDueMixin, HasSubmittedDataMixin],
  components: {
    IndexCard,
    DeliverablesData,
    SubmitForm,
    Submitted,
    NoRecordCard,
  },
  data() {
    return {
      tabName: "Deliverable",
      modelType: "deliverable",
      localDeliverables: {
        data: [],
      },
    };
  },
  watch: {
    activeTab: {
      handler: function(value) {
        if(this.sections[value].name == "deliverables") {
          this.filter.s = "";
          this.initSearch();
        } else {
          this.localDeliverables.data = this.model.deliverables;
        }
        this.enableInfiniteLoader = this.sections[value].name == "deliverables";
      },
      immediate: true,
    },
    model: {
      handler: function(value) {
        this.localDeliverables.data = value.deliverables;
      },
      deep: true,
    },
    infiniteLoad: {
      handler: function(value) {
        if(!this.isDashboard) {
          this.localDeliverables = value;
        }
      },
      deep: true,
    },
  },
  computed: {
    deliverables() {
      const availableDeliverables = this.$isUserAdmin && this.$viewProgramAsAdmin 
        ? this.localDeliverables 
        : {
            ...this.localDeliverables,
            data: this.localDeliverables.data.filter((deliverable) => this.hasItemValidVisibility(deliverable, this.model.id) || this.belongsToMe(deliverable.user_id)),
          };
      return availableDeliverables;
    },
  },
  created() {
    this.$bus.$on('viewSubmitted', this.onShowSubmitted);
  },
  beforeDestroy() {
    this.$bus.$off('viewSubmitted');
  },
  methods: {
    ...mapActions({
      doGetDeliverables: "deliverable/getAll",
    }),
    onGetAll(params) {
      params.perPage = 12;
      params.filter = {
        program: [this.model.id],
        strict: !this.filter.s,
      };
      params.s = this.filter.s;
      return this.doGetDeliverables(params).then((result) => {
        result.data = this.updateLocalPendingDuplications(this.modelType, result.data);
        if(this.isDashboard) {
          this.localDeliverables = result;
        }
        return result;
      });
    },
    checkShowCount(key) {
      if(this.isDashboard) {
        return this.model.deliverables_count > this.limitForDashboard && key === this.deliverables.data.length - 1;
      }
      return false;
    },
    onDuplicationDone(result) {
      this.localDeliverables.data.unshift(result);
      if(!this.isDashboard) {
        this.model.deliverables.unshift(result);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.metadata .v-list-item {
  min-height: 26px !important;
}
.status-chip {
  border-radius: 12px;
  position: absolute;
  bottom: 18px;
  right: 18px;
}
.metadata-section {
  height: 132px;
  background-color: #ffffff;
  border-radius: 8px;
  border: solid 0.5px #ececf1;
}
</style>
