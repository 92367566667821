<template>
  <VSkeletonLoader
    grid-list-lg
    fluid
    type="card-heading,card,actions"
    :loading="activity.isFirstLoad"
  >
    <VRow>
      <SaveHeader
        disable-default-actions
        :owner="this"
        :mode="mode"
        :go-back="() => navigateTo({ name: 'deliverable.index' })"
      >
        <template #title>
          Submit {{ featureName("Deliverables")
          }}<template v-if="realDeliverable">
            for {{ realDeliverable.title }}
          </template>
        </template>
      </SaveHeader>
      <VCol
        style="flex: 1 1 auto; max-height: calc(100% - 70px); overflow-y: auto"
      >
        <VCard>
          <VCardTitle
            ref="cardTitle"
            class="px-2"
            style="height: 100% !important; border: none !important;"
          >
            <span class="title">
              {{ previewMode ? "Preview: " : "" }}
              {{ realDeliverable.title }}
            </span>
            <VSpacer />
            <Linkable
              v-if="copyableLink"
              height="40px"
              min-width="auto"
              :small="false"
              :text-button="false"
              :icon-button="false"
              :expand-on-hover="false"
              :button-style="{ 
                padding: '0 8px',
                backgroundColor: 'rgba(251, 152, 14, 0.2)',
                boxShadow: 'none',
                marginRight: '12px',
              }"
              :icon-props="{ color: '#fb980e'}"
              :url="copyableLink"
            />
            <VBtn
              v-if="mode !== 'standalone'"
              height="40px"
              min-width="auto"
              color="#ececf1"
              class="px-2 no-shadow"
              @click="$emit('toggle:dialog')"
            >
              <VIcon>
                mdi-close-circle-outline
              </VIcon>
            </VBtn>
          </VCardTitle>
          <VCardText
            ref="cardText"
            style="overflow: hidden auto;"
          >
            <VRow
              row
              wrap
            >
              <VCol
                v-if="realDeliverable.content && realDeliverable.content !== '<p></p>'"
                cols="12"
              >
                <p v-html="realDeliverable.content" />
              </VCol>
              <VCol
                v-if="hasMessagesOrErrors"
                cols="12"
              >
                <Alerts
                  :messages="messages"
                  :errors="errors"
                />
              </VCol>
              <VCol
                v-if="($isUserAdmin || submitForUserMode) && !previewMode"
                cols="12"
              >
                <Autocomplete
                  v-model="form.user_id"
                  hide-details
                  hide-no-data-msg
                  item-text="name"
                  item-value="id"
                  append-inner-icon="arrow-down"
                  placeholder="Find user"
                  label="Select User"
                  :loading="activity.isLoading"
                  :items="options.users"
                  :filter-options="{
                    type: 'user',
                  }"
                  :getter="doGetUsers"
                  :error="$v.form.user_id.$error"
                  @input="$v.form.user_id.$touch()"
                  @blur="$v.$touch()"
                />
              </VCol>
              <!-- <VCol
                v-if="form.entity_type"
                cols="12"
              >
                <Autocomplete
                  v-model="form.entity_id"
                  filled
                  item-text="title"
                  item-value="id"
                  append-inner-icon="arrow-down"
                  placeholder="Select item"
                  :loading="activity.isLoading"
                  :disabled="!isFormReady || isFormInputsDisabled"
                  :items="entityOptions"
                  :label="selectedEntityTitle"
                  :filter-options="{
                    type: 'user',
                  }"
                  :getter="currentEntity.getter"
                />
              </VCol>
              <VCol
                v-if="form.entity_id"
                cols="12"
              >
                <Autocomplete
                  v-model="form.calendar_entry_id"
                  item-text="date"
                  item-value="id"
                  append-inner-icon="arrow-down"
                  placeholder="(Optional) Select instance"
                  label="(Optional) Select instance"
                  :loading="activity.isLoading"
                  :disabled="!isFormReady || isFormInputsDisabled"
                  :items="instanceOptions"
                />
              </VCol> -->
              <VCol
                v-if="form.files && form.files.length"
                cols="12"
              >
                <FilesList
                  :readonly="true"
                  :files="form.files"
                />
              </VCol>
              <VCol
                v-if="form.metadata instanceof Array && form.metadata.length"
                class="py-0"
              >
                <DynamicInputsSubmit
                  v-model="form.metadata"
                  container-class="dynamic-input-container px-2"
                  item-title-class="d-flex subtitle-2"
                  required-text="Required"
                  :card="false"
                  :loading="activity.isLoading"
                  :metadata-sections="form.metadata_sections"
                  :disabled="!isFormReady || isFormInputsDisabled"
                  :input-name="featureName('Deliverable')"
                  :validator="$v.form.metadata"
                  @validated="$v.form.$touch()"
                  @update:input="onUpdateMetadata"
                />
              </VCol>
            </VRow>
          </VCardText>
        </VCard>
      </VCol>
      <VCol
        v-if="!previewMode"
        class="mx-3 sticky sticky--bottom"
      >
        <VRow
          row
          wrap
          justify-end
        >
          <VBtn
            v-if="
              formHasId &&
                (submitForUserMode ||
                  realDeliverable.allow_multiple_submissions)
            "
            color="#60be39"
            class="mr-3"
            style="box-shadow: 0 1.5px 3px 0 rgba(250, 36, 111, 0.25);"
            :disabled="!isFormReady || isFormInputsDisabled"
            :loading="activity.isFormLoading"
            @click="onSaveAndReset"
          >
            <span class="white--text">Submit New</span>
          </VBtn>
          <VBtn
            color="#60be39"
            class="mr-3"
            style="box-shadow: 0 1.5px 3px 0 rgba(250, 36, 111, 0.25);"
            :disabled="!isFormReady || isFormInputsDisabled"
            :loading="activity.isFormLoading"
            @click="onSubmit"
          >
            <span class="white--text">{{ form.is_submitted ? "Re-" : "" }} Submit</span>
          </VBtn>
          <VBtn
            color="#60be39"
            class="mr-3"
            style="box-shadow: 0 1.5px 3px 0 rgba(250, 36, 111, 0.25);"
            :disabled="!isFormReady || isFormInputsDisabled"
            :loading="activity.isFormLoading"
            @click="onSave"
          >
            <span class="white--text">Save</span>
          </VBtn>
        </VRow>
      </VCol>
    </VRow>
  </VSkeletonLoader>
</template>

<script>
import DynamicInputsSubmit from "@/components/Elements/Forms/DynamicInputsSubmit";
import FormMixin from "@/mixins/Form";
import MetadataMixin from "@/mixins/Metadata";
import SavesSubmittedData from "../../mixins/SavesSubmittedData.vue";
import SaveHeader from "@/components/Elements/Navigation/SaveHeader";
import { mapActions } from "vuex";
import {
  required,
  requiredIf,
  minLength,
  between,
} from "vuelidate/lib/validators";
export default {
  name: "DeliverablesSubmitForm",
  components: {
    DynamicInputsSubmit,
    SaveHeader,
  },
  mixins: [FormMixin, MetadataMixin, SavesSubmittedData],
  props: {
    property: {
      type: String,
      default: "",
    },
    submitForUserMode: {
      type: Boolean,
      default: false,
    },
    entity: {
      type: Object,
      default: () => {},
    },
    deliverable: {
      type: Object,
      default: () => {},
    },
    entityType: {
      type: String,
      default: "",
    },
    copyableLink: {
      type: String,
      default: null,
    },
    previewMode: {
      type: Boolean,
      default: false,
    },
    program: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      modelType: "Deliverable",
      hasFiles: true,
      formEmitsEvents: false,
      selectedEntityType: null,
      options: {
        programs: [],
        supplements: [],
        primary_contents: [],
        pipelines: [],
        users: [],
      },
      deliverableFromRoute: null,
      defaultForm: {
        user_id: null,
        deliverable_id: null,
        program_id: null,
        supplement_id: null,
        primary_content_id: null,
        entity_id: null,
        entity_type: null,
        metadata: [],
      },
      types: [
        {
          id: "App\\Models\\Program",
          title: this.featureName("Program"),
          slug: "programs",
          getter: (params) => this.doGetPrograms(params),
        },
        // {
        //   id: "App\\Models\\Report",
        //   title: this.featureName("Report"),
        //   slug: "reports"
        // },
        {
          id: "App\\Models\\Supplement",
          title: this.featureName("Supplement"),
          slug: "supplements",
          getter: (params) => this.doGetSupplements(params),
        },
        {
          id: "App\\Models\\PrimaryContent",
          title: this.featureName("Content"),
          slug: "primary_contents",
          getter: (params) => this.doGetContent(params),
        },
        {
          id: "App\\Models\\User",
          title: this.featureName("User"),
          slug: "users",
          getter: (params) => this.doGetUsers(params),
        },
        {
          id: "App\\Models\\Pipeline",
          title: this.featureName("Pipeline"),
          slug: "pipelines",
          getter: (params) => this.doGetPipelines(params),
        },
        {
          id: "App\\Models\\PipelineSubmitted",
          title: `${this.featureName("Pipeline")} ${this.featureName(
            "Application"
          )}`,
          slug: "pipelines_submitted",
          getter: (params) => this.doGetPipelinesSubmitted(params),
        },
      ],
    };
  },
  validations: {
    form: {
      user_id: {
        required: requiredIf(function() {
          return this.$isUserAdmin;
        })
      },
      metadata: {
        $each: {
          validUrl: (input) => {
            if (input instanceof Object && input.value) {
              switch ((input.type || "").toLowerCase()) {
                case "url":
                case "video_link":
                  const regexp = /^((https|http):\/\/)?([\w\d\-\_\:\@]+)\.(.*)/;
                  return regexp.test(input.value);
              }
            }
            return true;
          },
          value: {
            validUrl: (input) => {
              if (input instanceof Object && typeof input.type === "string") {
                switch ((input.type || "").toLowerCase()) {
                  case "url":
                    const regexp = /^((https|http):\/\/)?([\w\d\-\_\:\@]+)\.(.*)/;
                    return !required(input.value) || regexp.test(input.value);
                    break;
                }
              }
              return true;
            },
            required: requiredIf((model) => {
              switch (model.type) {
                case "File":
                case "Google Drive":
                case "Image":
                  const isEmpty =
                    model.value instanceof Object
                      ? (model.value.url || "").length === 0
                      : true;
                  return model.is_required === true && isEmpty;
                  break;

                default:
                  return model.is_required === true;
                  break;
              }
            }),
            // max: (value, model) => {
            //   return parseInt((model.options || {}).maxLength) > 0 ? maxLength(model.options.maxLength)(value) : false;
            // }
          },
        },
      },
    },
  },
  computed: {
    realDeliverable() {
      return this.deliverableFromRoute || this.deliverable || {};
    },
    deliverableMetadata() {
      return this.realDeliverable.metadata || [];
    },
    deliverableHasAssociations() {
      return this.deliverableEntityTypes.length > 0;
    },
    entityTypes() {
      return this.deliverableHasAssociations
        ? this.deliverableEntityTypes
        : this.types;
    },
    deliverableEntityTypes() {
      return this.types.filter(
        (type) =>
          this.realDeliverable.hasOwnProperty(type.slug) &&
          this.realDeliverable[type.slug].length > 0
      );
    },
    entityOptions() {
      if (this.deliverableHasAssociations) {
        return this.selectedEntityType !== null &&
          this.realDeliverable[this.currentEntitySlug]
          ? this.realDeliverable[this.currentEntitySlug]
          : [];
      } else {
        return this.selectedEntityType !== null &&
          this.options[this.currentEntitySlug]
          ? this.options[this.currentEntitySlug]
          : [];
      }
    },
    currentEntity() {
      const entityTypes = this.entityTypes || [];
      const entity = this.selectedEntityType
        ? entityTypes.find((entity) => entity.id === this.form.entity_type)
        : {};
      return entity || {};
    },
    currentEntitySlug() {
      return this.currentEntity.slug;
    },
    instanceOptions() {
      const item =
        this.entityOptions instanceof Array
          ? this.entityOptions.find(
              (entity) => entity.id === this.form.entity_id
            )
          : {};
      return item ? item.instances : [];
    },
    existingMembers() {
      return this.form.members
        ? this.form.members.map((mentor) => mentor.id)
        : [];
    },
    selectedEntity() {
      return this.selectedEntityType !== null &&
        this.entityTypes instanceof Array
        ? this.entityTypes.find(
            (entity) => entity.id === this.form.entity_id
          ) || {}
        : {};
    },
    selectedEntityTitle() {
      return this.selectedEntity.title;
    },
    canBeAttached() {
      for (const i in this.entityTypes) {
        const entity = this.entityTypes[i];
        if (!entity instanceof Object) {
          return false;
        }
        const source = this.deliverableHasAssociations
          ? this.deliverable
          : this.options;
        if (
          source.hasOwnProperty(entity.slug) &&
          source[entity.slug] instanceof Array
        ) {
          return source[entity.slug].length > 0;
        }
      }
      return false;
    },
  },
  mounted() {
    if (!this.existing && (!this.$route.params.id || !this.useIdFromRoute)) {
      const deliverableId =
        this.$route.params.deliverable || this.$route.params.id;
      if (!(this.deliverable instanceof Object) && deliverableId) {
        this.deliverableFromRoute = { id: deliverableId };
      }
      this.prepareNewSubmission();
    } else {
      this.prepareExistingSubmission();
    }
  },
  updated() {
    this.updateContentHeight();
  },
  methods: {
    ...mapActions({
      doGetFormConfig: "deliverableSubmitted/getFormConfig",
      doSetDeliverableSubmittedByMe:
        "deliverableSubmitted/setDeliverableSubmittedByMe",
      doSaveDeliverableSubmitted: "deliverableSubmitted/save",
      doGetDeliverableSubmitted: "deliverableSubmitted/getOne",
      doGetDeliverable: "deliverable/getOne",
      doGetUsers: "users/getAll",
      doGetPipelines: "pipeline/getAll",
      doGetPipelinesSubmitted: "pipelineSubmitted/getAll",
      doGetPrograms: "program/getAll",
      doGetSupplements: "supplement/getAll",
      doGetContent: "content/getAll",
    }),
    updateContentHeight() {
      if(this.$refs.cardTitle) {
        const titleElementHeight = this.$refs.cardTitle.offsetHeight;
        this.$refs.cardText.style.maxHeight = "calc(100vh - " + (titleElementHeight + 140) + "px)";
      }
    },
    onLocalGetFormConfig() {
      return this.doGetFormConfig({
        id: this.realDeliverable.id,
      }).then((config) => {
        if (!this.form.metadata) {
          this.form.metadata = this.getMetadataForSubmit(config.metadata) || [];
        }
        this.types.map((t) => {
          this.realDeliverable[t.slug] = config[t.slug] || [];
        });
        if (this.existing && this.existing instanceof Object) {
          if (
            config.users.findIndex((u) => u.user_id === this.existing.user_id) <
            0
          ) {
            config.users.unshift(this.existing.user);
          }
        }
        return config;
      });
    },
    getGetter() {
      if (
        !this.deliverableHasAssociations &&
        this.currentEntity instanceof Object
      ) {
        const getter = this[this.currentEntity.getter];
        return getter ? (params) => getter(params) : null;
      } else {
        return null;
      }
    },
    onSubmit() {
      this.form.is_submitted = true;
      return this.onSave(true);
    },
    onSave(wasSubmitted) {
      this.form.deliverable_id =
        this.form.deliverable_id || this.realDeliverable.id;
      if (this.property) {
        this.form[this.property] = this.form.entity_id;
      }
      if(this.program) {
        this.form.program_id = this.program.id;
      }
      delete this.form.deliverable;
      return this.saveFormModel(this.doSaveDeliverableSubmitted).then(() => {
        this.form.metadata = this.uniqueMetadata(this.form.metadata);
        if (wasSubmitted === true && !this.submitForUserMode) {
          // this.$bus.$emit("toggle:dialog", {
          //   uid: this.parentUid,
          // });
          this.$emit("submitted", this.realDeliverable.id);
        }
      });
    },
    onGet(id) {
      return this.doGetDeliverableSubmitted({
        id: id,
        deliverable_id:
          this.$route.params.deliverable || this.realDeliverable.id,
      }).then((result) => {
        this.$nextTick(() => {
          this.setEntities(result);
          if (result.metadata instanceof Array) {
            result.metadata = this.uniqueMetadata(
              result.metadata.concat(this.deliverableMetadata)
            );
          } else {
            result.metadata = this.deliverableMetadata;
          }
        });
        return result;
      });
    },
    setEntities(model) {
      if (model instanceof Object && model.entity_type) {
        this.selectedEntityType = model.entity_type;
      } else if (model instanceof Object) {
        if (this.form.program_id) {
          this.selectedEntityType = "App\\Models\\Program";
          this.form.entity_id = model.program_id;
        } else if (this.form.primary_content_id) {
          this.selectedEntityType = "App\\Models\\Content";
          this.form.entity_id = model.primary_content_id;
        } else if (this.form.supplement_id) {
          this.selectedEntityType = "App\\Models\\Supplement";
          this.form.entity_id = model.supplement_id;
        }
        this.form.entity_type = this.selectedEntityType;
      }
      const user = (model || this.existing || this.form).user;
      if (user instanceof Object) {
        this.options.users.unshift(user);
      }
    },
    prepareExistingSubmission() {
      this.toggleEmitFormEvents();
      const deliverableId =
        this.$route.params.deliverable || this.$route.params.id;
      if (!(this.deliverable instanceof Object) && deliverableId) {
        this.deliverableFromRoute = { id: deliverableId };
        this.doGetDeliverable(deliverableId).then((deliverable) => {
          this.deliverableFromRoute = deliverable;
          // Ensure that the submitted form is loaded for edit if the user has already submitted a deliverable and the deliverable only allows one submission
          if (
            !this.$isUserAdmin &&
            !deliverable.allow_multiple_submission &&
            !this.existing &&
            !this.$route.params.id
          ) {
            this.form = {
              ...(deliverable.submitted_by_me || this.form),
              files: deliverable.files,
            };
          }
          this.emitLoadingDone();
        });
      } else {
        this.$nextTick(() => {
          this.doGetDeliverable(this.deliverable.id).then((deliverable) => {
            this.form.files = deliverable.files;
            this.emitLoadingDone();
          });
        });
      }
    },
    prepareNewSubmission() {
      this.toggleEmitFormEvents(true);
      this.emitLoading();
      if (this.existing instanceof Object) {
        this.form = this.existing;
        this.form.metadata =
          this.getMetadataForSubmit(
            this.uniqueMetadata(this.deliverableMetadata) || []
          ) || [];
        this.setEntities();
        this.emitLoadingDone();
      } else if (this.realDeliverable.id) {
        this.doGetDeliverable(this.realDeliverable.id).then((deliverable) => {
          this.form.deliverable_id = this.realDeliverable.id;
          // this.form.entity_id = result.id;
          this.form.files = deliverable.files;
          this.form.entity_type = this.entityType || this.form.entity_type;
          this.form.metadata =
            this.getMetadataForSubmit(deliverable.metadata) || [];
          // Needed to force recalculation of entity optios on first load.
          // Otherwise options will show no data available
          this.selectedEntityType = -1;
          this.types.map((t) => {
            this.options[t.slug] = (this.realDeliverable[t.slug] || [])
              .concat(deliverable[t.slug] || [])
              .unique("id");
          });
          this.doGetUsers().then((users) => {
            this.options.users = users.data;
            this.emitLoadingDone();
          });
        });
      } else {
        this.onLocalGetFormConfig().then((config) => {
          this.config = config;
          this.emitLoadingDone();
        });
      }
    },
  },
};
</script>
