<template>
  <div style="position: relative; height: 100%;">
    <VBtn 
      v-if="isBackEnabled"
      class="ml-7 my-3" 
      @click="$router.go(-1)"
    >
      <VIcon class="pr-1">
        mdi-arrow-left
      </VIcon>
      Back
    </VBtn>
    <VNavigationDrawer
      permanent
      floating
      disable-resize-watcher
      height="auto"
      mini-variant-width="80px"
      id="side-bar-menu"
      color="#f4f4f9"
      class="adjust-items-padding"
      :mini-variant="mini"
      :style="{
        'min-width': drawerMinWidth,
      }"
    >
      <VList
        nav
        dense
        class="pl-7"
        style="overflow: hidden auto;"
        :height="mini ? 'auto' : height"
      >
        <VListItem 
          class="header px-0"
          :style="{
            opacity: mini ? 0 : 1,
          }"
        >
          <VListItemTitle style="font-weight: 600 !important; font-size: 16px !important;">
            {{ menuItems.header }}
          </VListItemTitle>
        </VListItem>
        <template v-for="(item, index) in menuItems.items">
          <VListGroup
            v-if="item.subGroup"
            append-icon=""
            :key="index"
            :active-class="mini ? 'active-item' : ''"
            :value="getItemGroup(item)"
            @click="$router.push(item.subGroupItems[0].route)"
          >
            <template #activator>
              <VTooltip 
                right
                :disabled="!mini"
              >
                <template #activator="{ on }">
                  <div
                    v-on="on"
                    :class="{
                      'px-4': mini,
                    }"
                  >
                    <VListItemIcon
                      v-if="item.isImage || item.icon"
                      :class="{
                        'my-3': true,
                        'mr-3': !mini,
                        'mx-0': mini,
                      }"
                    >
                      <img
                        v-if="item.isImage"
                        :src="item.icon"
                      >
                      <VIcon
                        v-else
                        color="rgba(0, 0, 0, 0.87)"
                      >
                        {{ item.icon }}
                      </VIcon>
                    </VListItemIcon>
                  </div>
                </template>
                {{ item.title }}
              </VTooltip>
              <VListItemTitle class="mt-n3">
                <BetaBadge
                  enable-tooltip
                  :is-beta="item.beta"
                  :class="{
                    'd-block text-truncate': !item.beta,
                  }"
                >
                  {{ item.title }}
                </BetaBadge>
              </VListItemTitle>
            </template>
            <template v-if="!mini">
              <VListItem
                v-for="(subItem, key) in item.subGroupItems"
                exact
                exact-active-class="active-item"
                :key="`sub-group-item-${key}`"
                :value="true"
                :to="subItem.route"
              >
                <VListItemIcon
                  v-if="subItem.icon"
                  class="my-3 ml-0 mr-3"
                >
                  <img
                    v-if="subItem.isImage"
                    :src="subItem.icon"
                  >
                  <VIcon
                    v-else
                    color="rgba(0, 0, 0, 0.87)"
                  >
                    {{ subItem.icon }}
                  </VIcon>
                </VListItemIcon>
                <BetaBadge
                  enable-tooltip
                  :is-beta="subItem.beta"
                  :class="{
                    'd-block text-truncate': !subItem.beta,
                  }"
                >
                  <VListItemTitle>{{ subItem.title }}</VListItemTitle>
                </BetaBadge>
              </VListItem>
            </template>
          </VListGroup>
          <VListItem
            v-else
            exact
            exact-active-class="active-item"
            :key="`menu-item-${index}`"
            :value="true"
            :disabled="item.disabled || false"
            :to="item.route || { name: item.routeName, params: { team: $team.slug } }"
          >
            <VTooltip 
              right
              :disabled="!mini"
            >
              <template #activator="{ on }">
                <div
                  v-on="on"
                  :class="{
                    'px-4': mini,
                  }"
                >
                  <VListItemIcon
                    v-if="item.routeGroup || item.icon"
                    :class="{
                      'my-3': true,
                      'mr-3': !mini,
                      'mx-0': mini,
                    }"
                  >
                    <img
                      v-if="item.isImage"
                      :src="item.icon"
                    >
                    <VIcon
                      v-else
                      color="rgba(0, 0, 0, 0.87)"
                    >
                      {{ item.icon }}
                    </VIcon>
                  </VListItemIcon>
                  <b v-else-if="mini">{{ item.title.charAt(0).capitalize() }}</b>
                </div>
              </template>
              {{ item.title }}
            </VTooltip>
            <VListItemTitle 
              :class="{
                'mt-n3': true,
                'pr-8': item.beta,
              }"
            >
              <BetaBadge
                offset-y="30%"
                :enable-tooltip="item.enableBetaTooltip"
                :is-beta="item.beta"
                :class="{
                  'd-block text-truncate': !item.beta,
                }"
              >
                {{ item.title }}
              </BetaBadge>
            </VListItemTitle>
          </VListItem>
        </template>
      </VList>
    </VNavigationDrawer>
    <VTooltip right>
      <template #activator="{ on }">
        <VBtn
          v-on="on"
          fab
          rounded
          ref="collapseButton"
          height="32px"
          width="32px"
          min-width="32px"
          id="menu-collapse-button"
          :style="{
            bottom,
          }"
          @click="forceExpand = !forceExpand"
        >
          <VIcon>
          {{ forceExpand ? "mdi-chevron-left" : "mdi-chevron-right" }}
          </VIcon>
        </VBtn>
      </template>
      {{ forceExpand ? "Collapse" : "Expand" }}
    </VTooltip>
  </div>
</template>
<script>
import LeftMainMenuMixin from "@/mixins/LeftMainMenu";
export default {
  mixins: [LeftMainMenuMixin],
  props: {
    bottom: {
      type: String,
      default: "32px",
    },
    height: {
      type: String,
      default: "calc(100vh - 76px)",
    },
  },
  data() {
    return {
      backEnabledRoutes: ['deliverable.report'],
      mini: JSON.parse(localStorage.getItem("forceMenuToExpand")) === null ? false : !JSON.parse(localStorage.getItem("forceMenuToExpand")),
      forceExpand: JSON.parse(localStorage.getItem("forceMenuToExpand")) === null ? true : JSON.parse(localStorage.getItem("forceMenuToExpand")),
    };
  },
  watch: {
    forceExpand: {
      handler(val) {
        this.mini = !val;
        localStorage.setItem("forceMenuToExpand", val);
        this.$bus.$emit("forceMenuToExpand", val);
        this.$refs.collapseButton.$el.blur();
      },
    },
  },
  computed: {
    isBackEnabled() {
      return this.backEnabledRoutes.includes(this.$route.name);
    },
    drawerMinWidth() {
      if(this.mini) {
        return "auto";
      } else if(this.menuItems.header == "Workspace Administration") {
        return "280px";
      } else if(this.menuItems.header == "Content Library") {
        return "240px";
      }
      return "312px";
    },
  },
  methods: {
    getItemGroup(item) {
      if(this.$route.name === "settings" && ["#pipelines", "#pipelines-submitted", "#pipelines-export"].includes(this.$route.hash)) {
        return item.routeGroup === "pipeline";
      }
      if(this.$route.name === "program.view" && ["#people", "#people-permissions", "#people-invitations"].includes(this.$route.hash)) {
        return item.routeGroup === "programPeople";
      }
      return item.routeGroup === this.$route.name.split(".")[0];
    },
  },
}
</script>
<style lang="scss">
#menu-collapse-button {
  position: absolute;
  right: 14px;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
#side-bar-menu {
  z-index: 6;
  max-width: 320px;

  .v-navigation-drawer__content {
    overflow: hidden;
  }
  .v-list-group__header__append-icon {
    margin: 0;
    min-width: auto;
  }
  .v-list-item .v-list-item__title {
    font-weight: 400 !important;
    font-size: 14px !important;
  }
  .v-list-item {
    border-radius: 10px;
    .v-list-item__title, .beta-chip {
      font-weight: 400 !important;
      font-size: 14px !important;
    }
  }
  .v-list-group__items {
    margin-left: 40px;
  }
  .v-list-item__icon img {
    width: 24px;
    height: 24px;
    object-fit: contain;
  }
  .v-list-item--active::before {
    opacity: 0;
  }
  .v-list-item:hover::before {
    border-radius: 10px;
  }
  .active-item {
    background-color: white;
    .v-list-item__title, .beta-chip {
      font-weight: 600 !important;
      font-size: 14px !important;
    }
  }
  .v-list-group--active {
    border-radius: 10px;
    color: rgba(0, 0, 0, 0.87) !important;
  }
  .v-list-group__items {
    margin-left: 48px;
  }
  .v-skeleton-loader__avatar {
    border-radius: 50%;
    height: 32px;
    width: 32px;
  }
}
.adjust-items-padding {
  .v-list-item:not(.header) {
    padding-left: 12px !important;
    padding-right: 12px !important;
  }
}
</style>