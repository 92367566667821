<template>
  <VRow
    column
    fill-height
  >
    <VCol style="flex: 1 1 auto">
      <CurrentFilter
        v-if="$route.hash === '' && getQueryFilterValues.length > 0"
        class="rounded-lg pb-6 mx-0 mb-n3 white"
        :filter="filter"
        :filter-values="$route.hash === '' ? getQueryFilterValues : []"
        :is-loading="activity.isLoading"
        @removeFilter="onRemoveFilter"
        @resetFilter="onResetFilter"
      />
      <DataViewUI
        :data="getData"
        :headers="headers"
        :is-loading="activity.isLoading"
        :hide-main-title="hasExistingData"
        :hide-records-info="hasExistingData"
        :hide-actions="hasExistingData"
        :disable-sort="hasExistingData"
        :class="{
          'pa-3 pt-0': hasExistingData,
        }"
        @paginate="onGetMore"
        @search="onSearch"
      >
        <template
          slot="items-cells"
          slot-scope="props"
        >
          <td>
            <VRow>
              <VCol class="d-flex align-center">
                <span 
                  class="d-block text-truncate" 
                  style="max-width: 360px;"
                >
                  {{ props.item.title }}
                </span>
                <Linkable :url="getFullyQualifiedUrlForItem(`${modelTypeRoute}.view`, props.item.id)" />
              </VCol>
            </VRow>
          </td>
          <!-- <td>{{ getContentType(props.item) || '--' }}</td> -->
          <template v-if="$isUserAdmin && $viewProgramAsAdmin">
            <td>
              <UserModal :user="props.item.user" />
            </td>
            <td>
              <Tooltip
                text-color="white"
                :icon="false"
                :as-button="false"
                :text="false"
                :color="props.item.is_active ? 'success' : 'warning'"
                :button-text="props.item.is_active ? 'Yes' : 'No'"
                :title="
                  props.item.is_active
                    ? 'This is active and visible to all allowed roles'
                    : 'This is inactive and invisible to all allowed roles'
                "
              />
            </td>
            <td>
              <Tooltip
                text-color="white"
                :icon="false"
                :as-button="false"
                :text="false"
                :color="
                  props.item.allow_multiple_submissions ? 'success' : 'warning'
                "
                :button-text="
                  props.item.allow_multiple_submissions
                    ? 'Multiple Times'
                    : 'Only Once'
                "
                :title="
                  props.item.allow_multiple_submissions
                    ? 'Multiple submissions are allowed'
                    : 'Only one submission is allowed per calendar entry'
                "
              />
            </td>
            <!-- <td>{{ props.item.due_on ? $timezone.formatDateTime(props.item.due_on) : '(not set)' }}</td> -->
            <td>
              <VChip
                :text-color="props.item.submitted_count ? 'white' : 'default'"
                :color="props.item.submitted_count ? 'black' : 'default'"
              >
                {{ props.item.submitted_count || 0 }}
              </VChip>
            </td>
            <td v-if="isColumnVisible('value', 'due_count')">
              <VChip
                :text-color="props.item.due_count > 0 ? 'white' : 'default'"
                :color="props.item.due_count > 0 ? 'error' : 'default'"
              >
                {{ props.item.due_count || 0 }}
              </VChip>
            </td>
          </template>
          <template v-else-if="$isUserMentor || $viewProgramAsMentor">
            <td>
              <template v-if="!hasSaved(props.item.submitted_by_me)">
                <VChip color="warning">
                  Not Started
                </VChip>
              </template>
              <template v-else>
                <template v-if="hasSubmitted(props.item.submitted_by_me)">
                  {{
                    $timezone.formatDateTime(
                      props.item.submitted_by_me.submitted_on
                    )
                  }}
                </template>
                <template v-else>
                  <VChip color="info">
                    In Progress
                  </VChip>
                </template>
              </template>
            </td>
            <td>
              <VChip
                :text-color="
                  hasSubmitted(props.item.submitted_by_me) ? 'white' : 'auto'
                "
                :color="
                  hasSubmitted(props.item.submitted_by_me)
                    ? 'success'
                    : 'disabled'
                "
              >
                {{ hasSubmitted(props.item.submitted_by_me) ? "Yes" : "No" }}
              </VChip>
            </td>
            <td>
              <VChip
                :text-color="props.item.submitted_count ? 'white' : 'default'"
                :color="props.item.submitted_count ? 'black' : 'default'"
              >
                {{ props.item.submitted_count || 0 }}
              </VChip>
            </td>
          </template>
          <template v-else>
            <td>
              <template v-if="!hasSaved(props.item.submitted_by_me)">
                <VChip color="warning">
                  Not Started
                </VChip>
              </template>
              <template v-else>
                <template v-if="hasSubmitted(props.item.submitted_by_me)">
                  {{
                    $timezone.formatDateTime(
                      props.item.submitted_by_me.submitted_on
                    )
                  }}
                </template>
                <template v-else>
                  <VChip color="info">
                    In Progress
                  </VChip>
                </template>
              </template>
            </td>
            <td>
              <VChip
                :text-color="
                  hasSubmitted(props.item.submitted_by_me) ? 'white' : 'auto'
                "
                :color="
                  hasSubmitted(props.item.submitted_by_me)
                    ? 'success'
                    : 'disabled'
                "
              >
                {{ hasSubmitted(props.item.submitted_by_me) ? "Yes" : "No" }}
              </VChip>
            </td>
          </template>
          <td class="actions">
            <Menu
              v-if="!$isUserStudent"
              :disabled="getIsDisabled(props.item)"
              :loading="props.item.isLoading"
            >
              <VListItem
                v-if="hasActionsAccess(props.item)"
                :href="
                  getHrefForItem(`${modelTypeRoute}.update`, props.item.id)
                "
                @click.prevent="$router.push({
                  name: 'deliverable.update',
                  params: {
                    id: props.item.id,
                    team: $team.slug,
                  },
                })"
              >
                <VListItemTitle>
                  <VIcon small>
                    edit
                  </VIcon>&nbsp;Edit
                </VListItemTitle>
              </VListItem>
              <VListItem
                :href="getHrefForItem(`${modelTypeRoute}.view`, props.item.id)"
                @click.prevent="onSetViewDialogModel(props.item)"
              >
                <VListItemTitle>
                  <VIcon small>
                    visibility
                  </VIcon>&nbsp;View
                </VListItemTitle>
              </VListItem>
              <VListItem @click="openPreviewDialog(props.item.id)">
                <VListItemTitle>
                  <VIcon small>
                    mdi-file-eye-outline
                  </VIcon>&nbsp;Preview
                </VListItemTitle>
              </VListItem>
              <VListItem
                v-if="$isUserAdmin"
                :disabled="!props.item.submitted_count"
                :href="getHrefForItem(`${modelTypeRoute}.report`, props.item.id)"
                @click.prevent="$router.push({
                  name: 'deliverable.report',
                  params: {
                    id: props.item.id,
                    team: $team.slug,
                  },
                })"
              >
                <VListItemTitle>
                  <VIcon small>
                    mdi-chart-bar
                  </VIcon>&nbsp;Reporting
                </VListItemTitle>
              </VListItem>
              <VListItem
                v-if="
                  props.item.userNeedsToSubmitReports ||
                    (($isUserAdmin ||
                      $isUserMentor) && !$viewProgramAsStudent)
                "
                :href="
                  getHrefForItem(`${modelTypeRoute}.submit`, { deliverable: props.item.id })
                "
                @click.prevent="
                  setSubmittedDialogData(props.item, 'deliverables', true)
                "
              >
                <VListItemTitle>
                  <VIcon small>
                    assignment_ind
                  </VIcon>
                  Submit For User
                </VListItemTitle>
              </VListItem>
              <VListItem
                v-if="!$isUserAdmin || !$viewProgramAsAdmin"
                :href="
                  getHrefForItem(`${modelTypeRoute}.submit`, { deliverable: props.item.id })
                "
                @click.prevent="
                  setSubmittedDialogData(props.item, 'deliverables')
                "
              >
                <VListItemTitle>
                  <VIcon small>
                    assignment
                  </VIcon>
                  <template v-if="!hasSaved(props.item.submitted_by_me)">
                    Submit Yours
                  </template>
                  <template v-else>
                    <template
                      v-if="
                        hasSubmitted(props.item.submitted_by_me) &&
                          props.item.allow_multiple_submissions
                      "
                    >
                      Submit Again
                    </template>
                    <template v-else>
                      Update Submission
                    </template>
                  </template>
                </VListItemTitle>
              </VListItem>
              <VListItem
                v-if="($isUserAdmin || $isUserMentor) && !$viewProgramAsStudent"
                :href="
                  getHrefForItem(`${modelTypeRoute}.submitted`, props.item.id)
                "
                @click.prevent="onShowSubmitted(props.item)"
              >
                <VListItemTitle>
                  <VIcon small>
                    check
                  </VIcon>&nbsp;View Submitted
                </VListItemTitle>
              </VListItem>
              <VListItem
                v-if="hasActionsAccess(props.item) && !hasExistingData"
                :to="{
                  hash: '#import',
                  query: {
                    deliverable_id: props.item.id,
                  },
                }"
              >
                <VListItemTitle>
                  <VIcon small>
                    south
                  </VIcon>&nbsp;Import
                </VListItemTitle>
              </VListItem>
              <VListItem
                v-if="($isUserAdmin || $isUserMentor) && !$viewProgramAsStudent && !hasExistingData"
                :to="{
                  hash: '#export',
                  query: {
                    deliverable_id: props.item.id,
                  },
                }"
              >
                <VListItemTitle>
                  <VIcon small>
                    north
                  </VIcon>&nbsp;Export
                </VListItemTitle>
              </VListItem>
              <VListItem
                v-if="$isUserAdmin && $viewProgramAsAdmin"
                @click.prevent="onDuplicateItem(props.item, false, false, true, true)"
              >
                <VListItemTitle>
                  <VIcon small>
                    content_copy
                  </VIcon>&nbsp;Duplicate
                </VListItemTitle>
              </VListItem>
              <VListItem
                v-if="hasActionsAccess(props.item)"
                @click.prevent="onDeleteItem(props.item)"
              >
                <VListItemTitle color="error">
                  <VIcon
                    small
                    color="error"
                  >
                    delete
                  </VIcon>&nbsp;Delete
                </VListItemTitle>
              </VListItem>
            </Menu>
            <VBtn
              v-else
              :color="hasSaved(props.item.submitted_by_me) ? 'info' : ''"
              :href="getHrefForItem(`${modelTypeRoute}.submit`, { deliverable: props.item.id })"
              @click.prevent="
                setSubmittedDialogData(props.item, 'deliverables')
              "
            >
              <VIcon small>
                assignment
              </VIcon>
              <template v-if="!hasSaved(props.item.submitted_by_me)">
                Submit Yours
              </template>
              <template v-else>
                <template
                  v-if="
                    hasSubmitted(props.item.submitted_by_me) &&
                      props.item.allow_multiple_submissions
                  "
                >
                  Submit Again
                </template>
                <template v-else>
                  Update Submission
                </template>
              </template>
            </VBtn>
          </td>
        </template>
      </DataViewUI>
      <FormDialog
        hide-actions
        custom-class="no-shadow dialog-card-shadow"
        :scrollable="false"
        :is-card-center="$isUserAdmin"
        :transparent="$isUserAdmin"
        :hide-title="$isUserAdmin"
        :max-width="$isUserAdmin ? '100%' : '570px'"
        :current-model="currentModel"
        :is-dialog-open="dialogs.form"
        :title="
          currentModel
            ? currentModel.title
            : `Add ${featureName(modelType.capitalize()).singularize()}`
        "
        :model-type="modelType"
        :button-text="$isUserAdmin ? 'Save Form' : 'Submit'"
        @toggle:form:dialog="onToggleFormDialog"
      >
        <template #default="props">
          <Save
            v-if="hasActionsAccess()"
            ref="form"
            mode="dialog"
            :form-props="props"
            :id="currentModel ? currentModel.id : null"
            @saved="(result) => $emit('updated', result)"
            @duplicated="(result) => $emit('duplicated', result)"
            @updateModel="onToggleFormDialog('form')"
            @toggle:form:dialog="onToggleFormDialog"
            @deleteItem="(params) => onDeleteItem(params, null, null, true)"
          />
          <Submit
            v-else
            ref="form"
            mode="dialog"
            :deliverable="currentModel"
          />
        </template>
      </FormDialog>
      <Confirm ref="confirmDelete" />
      <Confirm
        ref="confirmDuplicate"
        confirm-color="info"
        cancel-color="disabled"
      />
      <Dialog
        max-width="75%"
        :is-dialog-open="dialogs.view"
        :title="`View ${featureName('Deliverable')}`"
        @toggle:dialog="onToggleViewDialog"
      >
        <ViewComponent
          ref="viewItem"
          mode="dialog"
          :id="currentModel ? currentModel.id : null"
        />
      </Dialog>
      <Dialog
        fullscreen
        :is-dialog-open="submittedDialogs.submitted"
        :title="
          `Submitted ${featureName('Deliverables')}: ${
            submittedModel.title ? submittedModel.title : ''
          }`
        "
        @toggle:dialog="onToggleSubmittedDialog"
      >
        <Submitted
          mode="dialog"
          :deliverable-id="submittedModel.id"
          :is-from-existing-data="hasExistingData"
        />
      </Dialog>
      <Dialog
        v-slot="parent"
        max-width="640px"
        card-text-class="overflow-hidden"
        :dismissable="false"
        :persistent="true"
        :is-dialog-open="submittedDialogs.deliverables"
        @toggle:dialog="onToggleSubmittedDeliverablesDialog"
      >
        <SubmitForm
          v-if="submitted"
          mode="dialog"
          :use-id-from-route="!hasExistingData"
          :submit-for-user-mode="submitForUserMode"
          :id="
            submitForUserMode ? null : (submittedModel.submitted_by_me || {}).id
          "
          :existing="submitForUserMode ? null : submittedModel.submitted_by_me"
          :deliverable="submittedModel"
          :items="submitted.data"
          :parent-uid="parent.uid"
          :copyable-link="getHrefForItem(`${modelTypeRoute}.submit`, { deliverable: submittedModel.id })"
          @toggle:dialog="onToggleSubmittedDeliverablesDialog"
        />
      </Dialog>
      <Dialog
        max-width="520px"
        :dismissable="false"
        :persistent="true"
        :is-dialog-open="dialogs.preview.status"
      >
        <VSkeletonLoader
          grid-list-lg
          fluid
          type="card-heading, card"
          :loading="!dialogs.preview.item"
        >
          <SubmitForm
            preview-mode
            mode="dialog"
            :use-id-from-route="false"
            :deliverable="dialogs.preview.item"
            :existing="dialogs.preview.item"
            :copyable-link="getFullyQualifiedUrlForItem(`${modelTypeRoute}.submit`, { deliverable: dialogs.preview.item?.id })"
            @toggle:dialog="closePreviewDialog"
          />
        </VSkeletonLoader>
      </Dialog>
    </VCol>
  </VRow>
</template>

<script>
import SearchMixin from "@/mixins/Search";
import HasSubmittedDataMixin from "@/mixins/HasSubmittedData";
import HasDueMixin from "@/mixins/HasDueOn";
import Save from "./Save";
import Submit from "./Submit";
import SubmitForm from "./SubmitForm";
import ViewComponent from "./View";
import Submitted from "./Submitted";
import { mapActions } from "vuex";

export default {
  name: "DeliverablesData",
  components: {
    Save,
    Submit,
    SubmitForm,
    ViewComponent,
    Submitted,
  },
  mixins: [SearchMixin, HasSubmittedDataMixin, HasDueMixin],
  data() {
    return {
      autoloadViewModel: false,
      date: null,
      modelType: "deliverable",
      deliverables: {},
      dialogs: {
        preview: {
          status: false,
          item: null,
        },
      },
    };
  },
  computed: {
    headers() {
      let headers = [
        {
          text: "Name",
          align: "left",
          sortable: true,
          value: "title",
        },
        /* {
          text: "For",
          align: "left",
          sortable: false,
          value: "program.name",
        }, */
      ];
      if (this.$isUserAdmin && this.$viewProgramAsAdmin) {
        headers = headers.concat([
          {
            text: "Created By",
            align: "left",
            sortable: true,
            value: "user.name",
          },
          {
            text: "Accepting Responses",
            align: "left",
            sortable: true,
            value: "is_active",
            hidden: this.$isUserStudent,
          },
          {
            text: "Submit Type",
            align: "left",
            sortable: true,
            value: "allow_multiple_submissions",
            hidden: this.$isUserStudent,
          },
          /* {
            text: "Due",
            align: "left",
            sortable: true,
            value: "due_on",
          }, */
          {
            text: "# Submitted",
            align: "left",
            sortable: true,
            value: "submitted_count",
          },
          {
            text: "Outstanding",
            align: "left",
            sortable: true,
            value: "due_count",
            hidden: true,
          },
        ]);
      } else {
        headers = headers.concat([
          {
            text: "Submitted On",
            align: "left",
            sortable: true,
            value: "submitted_on",
          },
          {
            text: "Submitted",
            align: "left",
            sortable: true,
            value: "is_submitted",
          },
          {
            text: "# Submitted",
            align: "left",
            sortable: true,
            value: "submitted_count",
            hidden: this.$isUserStudent,
          },
        ]);
      }
      return headers.filter((h) => !h.hidden);
    },
    getData() {
      if(this.deliverables && this.deliverables.data) {
        return this.deliverables;
      }
      return this.data;
    },
  },
  watch: {
    userConfig: function(value) {
      this.config = value;
    },
  },
  mounted() {
    const deliverableId =
      this.$route.params.deliverable || this.$route.query.deliverable_id;
    if (deliverableId) {
      this.filter.filter = { deliverable: [deliverableId] };
    }
    this.doGetDeliverable(this.deliverableId).then((deliverable) => {
      this.metadataHeaders = (deliverable.metadata || []).map((m) =>
        this.prepareSubmittedHeader(m)
      );
      this.setEnableSubmittedHeaders(true);
      switch (this.$route.hash) {
        case "#submitted":
          this.onShowSubmitted(deliverable);
          break;
      }
    });
  },
  methods: {
    ...mapActions({
      doGetIndexConfig: "deliverable/getIndexConfig",
      doGetDeliverables: "deliverable/getAll",
      doGetDeliverable: "deliverable/getOne",
      doDeleteDeliverable: "deliverable/delete",
      doDuplicateDeliverable: "deliverable/duplicate",
    }),
    onGetIndexConfig() {
      return this.doGetIndexConfig().then((result) => {
        this.$emit("setStatsData", result.stats);
        return result;
      });
    },
    onDuplicate(params) {
      return this.doDuplicateDeliverable(params);
    },
    onDelete(deliverable) {
      return this.doDeleteDeliverable(deliverable.id);
    },
    onGet(id) {
      return this.doGetDeliverable(id).then((result) => {
        if (result.calendars instanceof Array) {
          this.calendars.data = [result.calendars];
        }
        return result;
      });
    },
    onGetAll(params) {
      params._withCounts = true;
      params._with = "user";
      // params._withCustom = true;
      return this.doGetDeliverables(params).then((result) => {
        // Handled duplication loading card
        if(!params.hasOwnProperty("page") || (params.hasOwnProperty("page") && params.page == 1)) {
          const pendingDuplications = this.getGroupItemLocalStorage("pending-duplications", this.modelType);
          if(pendingDuplications && Object.keys(pendingDuplications).length > 0) {
            Object.keys(pendingDuplications).map((itemKey) => {
              const itemIndex = result.data.findIndex((item) => item.duplicated_using_id == itemKey.split("-")[0]);
              if(itemIndex > -1) {
                this.$delete(pendingDuplications, itemKey);
                this.removeGroupItemLocalStorage("pending-duplications", this.modelType, itemKey);
              }
            });
          }
          this.$set(result, "data", [
            ...Object.values(pendingDuplications),
            ...result.data,
          ]);
        }
        this.deliverables = result;
        return result;
      });
    },
    getContentType(deliverable) {
      let types = [];
      if (deliverable.programs && deliverable.programs.length) {
        types.push(this.featureName("Programs"));
      }
      if (deliverable.supplements && deliverable.supplements.length) {
        types.push(this.featureName("Supplements"));
      }
      if (deliverable.primary_contents && deliverable.primary_contents.length) {
        types.push(this.featureName("Content"));
      }
      return types.join(", ");
    },
    deleteItem(params) {
      this.onDeleteItem(params).then((result) => {
        if (result) {
          this.onToggleFormDialog("form");
        }
      });
    },
    openPreviewDialog(id) {
      this.dialogs.preview.status = !this.dialogs.preview.status;
      this.onGet(id).then((result) => {
        this.dialogs.preview.item = result;
      });
    },
    closePreviewDialog() {
      this.dialogs.preview.item = null;
      this.dialogs.preview.status = !this.dialogs.preview.status;
    },
  },
};
</script>

<style lang="scss">
.dialog-card-shadow {
  .form-dialog {
    box-shadow: 0px 11px 15px -7px rgb(0 0 0 / 20%), 0px 24px 38px 3px rgb(0 0 0 / 14%), 0px 9px 46px 8px rgb(0 0 0 / 12%) !important;
  }
}
.forms-save-dialog-height {
  height: 550px;
}
</style>