import Vue from "vue";
import env from "@/config/env";
import axios from "@/config/vue-axios";
import createStore from "@/store";
import router from "@/router";
import "@/config/utilities/Logging";
import "@/config/vue-auth";
import vuetify from "@/config/vuetify";
import "@/config/vue-echo";
import "@/config/utilities";
import "@/config/font-awesome";
import "@/config/components";
import "@/config/google-oauth";
import "@/config/vue-hotjar";
import "@/config/highcharts";
import * as Exceptions from "@/config/utilities/Exceptions";
import SparkForm from "@/config/spark";
import infiniteScroll from "vue-infinite-loading";
import VueSlimScroll from "vue-slimscroll";
import Vuelidate from "vuelidate";
import VueCurrencyFilter from "vue-currency-filter";
import VueIntercom from "vue-intercom";
import VueAnalytics from "vue-analytics";
import VueConfetti from "vue-confetti";
import "vue-echo";
// import ChartJsPluginDataLabels from "chartjs-plugin-datalabels";
// Chart.plugins.unregister(ChartJsPluginDataLabels);
// Register the plugin to all charts:
// Chart.plugins.register(ChartJsPluginDataLabels);

window.app = {};
import MainApp from "@/Main.vue";

let app = window.app;

// Let's not duplicate the vue app
if (!app._isVue) {
	Vue.use(Vuelidate);
	Vue.use(infiniteScroll, {
		props: {
			spinner: "waveDots",
			distance: 25,
			/* other props need to configure */
		},
		system: {
			throttleLimit: 500,
			/* other settings need to configure */
		},
		slots: {
			noMore: "No more", // you can pass a string value,
			noResults: "",
		},
	});
	Vue.use(VueSlimScroll);
	Vue.use(VueCurrencyFilter);
	Vue.use(VueIntercom, {
		appId: env.VUE_APP_INTERCOM_APP_ID,
		hideDefaultLauncher: true,
	});
	Vue.use(VueAnalytics, {
		id: "UA-149580863-1",
		router,
		debug: {
			sendHitTask: process.env.NODE_ENV === "production",
		},
		autoTracking: {
			exception: true,
			exceptionLogs: false,
		},
		batch: {
			enabled: true, // enable/disable
			amount: 2, // amount of events fired
			delay: 500, // delay in milliseconds
		},
	});
	Vue.use(VueConfetti);

	window._ = require("underscore");
	window.SparkForm = SparkForm;

	Vue.config.productionTip = env.isDev || env.isStaging;
	Vue.config.performance = env.isDev;
	Vue.config.devtools = env.isDev;
	let appName = "WeThrive";
	if (env.isDev) {
		appName += " - Dev";
	} else if (env.isStaging) {
		appName += " - Staging";
	}
	Vue.$bus = new Vue();
	Vue.prototype.$bus = Vue.$bus;
	Vue.prototype.env = env;
	Vue.prototype.$appName = appName;
	Vue.prototype.switchToTeamFromRouteIfNecessary = function(to, from, next) {
		let oldTeam = from.params.team;
		let newTeam = to.params.team;
		newTeam =
			newTeam || (this.$team instanceof Object ? this.$team.slug : "no-team");
		oldTeam =
			oldTeam ||
			(this.$team instanceof Object ? this.$team.slug : null) ||
			newTeam;
		if (
			newTeam !== "no-team" &&
			oldTeam !== "no-team" &&
			((newTeam && !oldTeam) || (oldTeam && oldTeam != newTeam))
		) {
			const teams = this.$user.teams;
			const id =
				teams instanceof Array
					? (teams.find((team) => team.slug === newTeam) || {}).id
					: newTeam;
			if (!id) {
				this.$log.debug(`Couldn't find [${newTeam}] in the user's teams`);
				next();
				window.location.href = "/";
				return Promise.resolve();
			}
			this.$log.debug("Switching from", oldTeam, "to", newTeam, to, from);
			setTimeout(() => {
				/**
				 * TODO: Figure out how to do this without reloading the entire page
				 * Right now replacing the team is too costly performance wise
				 */
				const newUrl = (to.fullPath || window.location.href).replace(
					"/" + oldTeam,
					"/" + newTeam
				);
				this.$log.debug("Reloading page to", newUrl);
				window.location.replace(newUrl);
				window.location.reload();
			}, 1000);
			return this.$store.dispatch("user/setCurrentTeam", id).then(() => {
				return this.$team;
			});
		} else {
			next();
			this.$log.debug(
				"No need to switch from",
				oldTeam,
				"to",
				newTeam,
				this.$team
			);
			return Promise.resolve(this.$team);
		}
	};
	Vue.prototype.redirectUsersToPrograms = function (to, from, next) {
		if (!this.$isUserApproved && (this.$user || {}).has_submitted_application) {
			next();
		} else if (to.name == "dashboard" && to.hash !== "#edit-application") {
			next({ name: "program.index" });
		}
	};
	// Force mobile user to redirect to Launch/Download app page
	Vue.prototype.redirectUsersToLaunch = function (to, from, next) {
		if (/webOS|iPhone|iPad|iPod|BlackBerry|Android/i.test(navigator.userAgent) && 
			!["dashboard", "reset", "team-launch"].includes(to.name) && 
			!to.query.invitation
		) {
			if(to.params.hasOwnProperty("team") && to.params.hasOwnProperty("id")) {
				const featureRouteName = to.name.split(".");
				const featureName = featureRouteName[0] == "faq" ? featureRouteName[0] : (featureRouteName[0] == "present" ? "lesson" : featureRouteName[0]).pluralize();
				if(to.params.team == "wethrive-education") {
					window.location.href = `https://wethrive-yarn.onelink.me/PESd/fdvpol90?action=${featureName}&id=${to.params.id}`;
				} else if(to.params.team == "dycd") {
					window.location.href = `https://wethrive-dycd.onelink.me/ENRE/fr5mc8qo?action=${featureName}&id=${to.params.id}`;
				} else {
					window.location.href = `https://wethrive.onelink.me/7Zg8/7sckelno?action=${featureName}&id=${to.params.id}`;
				}
			}
			next({ name: "team-launch", params: { team: "wethrive" } });
		}
	};
	// Global mixin method
	Vue.mixin({
		methods: {
			/*
			 * Issue fixed for all input type number fields.
			 * Issue Link - https://airtable.com/tblnFs3b08tvsN1xL/rec8HgQEbQr88G7lJ
			 */
			numericOnly(e) {
				if (!(e.keyCode === 8 || (e.charCode >= 48 && e.charCode <= 57))) {
					e.preventDefault();
				}
			},
		},
		computed: {
			fileMaxSize() {
				return 204857600;
			},
		},
	});

	app = new Vue({
		el: "#app",
		router,
		axios,
		vuetify,
		computed: {
			env() {
				return env;
			},
		},
		created: function() {
			document.title = "WeThrive";
			// this.$store.dispatch('user/loadFromCache').then(user => {
			// this.$auth.user(user);   this.$auth.check(); }).catch(() => {
			// this.$log.warn("No user"); });
			Vue.prototype.$store = createStore(this);
			Vue.$store = Vue.prototype.$store;
		},
		render: (h) => h(MainApp),
	});

	window.app = app;
}

export default app;
