import Vue from 'vue';
import HighchartsVue from 'highcharts-vue';
import Highcharts from 'highcharts';

Highcharts.setOptions({
    credits: {
        enabled: false,
    },
    title: {
        style: {
            display: 'none',
        },
    },
});

Vue.use(HighchartsVue);

export default {};